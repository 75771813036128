import emitter from "tiny-emitter/instance"

let debugEventsEnabled = false

export const setEventDebug = debug => {
  debugEventsEnabled = debug
}

function debugEvent(args) {
  if (!debugEventsEnabled) return
  const [eventName, ...eventArgs] = args
  console.log(`event-bus.ts ${eventName}`, eventArgs)
}

export default {
  $on: (...args) => {
    debugEvent(args)
    return emitter.on(...args)
  },
  $once: (...args) => {
    debugEvent(args)
    return emitter.once(...args)
  },
  $off: (...args) => {
    debugEvent(args)
    return emitter.off(...args)
  },
  $emit: (...args) => {
    debugEvent(args)
    return emitter.emit(...args)
  },
}
